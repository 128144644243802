import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import ProductItem from '../../../Components/ProductItem';
import * as AppActions from '../../../AppActions';

export default function ProductsSection(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const {products, onItemClick} = props;

  return (
    <Wrapper mobile={mobile}>
      <div className="container">
        {products.map((product, index) => {
          return (
            <ProductItem
              key={product.id}
              product={product}
              mobile={mobile}
              onClick={(evt) => onItemClick(product, evt)}
            />
          );
        })}
      </div>
      <div className="action">
        <Button size="large" onClick={() => AppActions.navigate('/products')}>
          More
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 60px;

  & > .container {
    display: grid;
    justify-content: center;

    ${(props) =>
      props.mobile
        ? ` grid-template-columns: repeat(auto-fit, 150px);  grid-gap: 20px;`
        : ` grid-template-columns: repeat(auto-fit, 250px);  grid-gap: 20px;`}
  }

  & > .action {
    display: flex;
    justify-content: center;

    ${(props) =>
      props.mobile
        ? `margin-top: 40px;`
        : `margin-top: 80px;
      `}
  }

  ${(props) =>
    props.mobile ? `margin-bottom: 50px;` : `margin-bottom: 100px;`}
`;
